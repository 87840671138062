export const reviewsData = [
    {
      review: "I recently underwent a smile designing treatment at Elite Dental Care, and I had an exceptional experience. From the moment I walked into Elite Dental Care, I felt welcomed and well-informed. Dr. Shobana provides a top-notch smile designing service, marked by professionalism, advanced technology, and personalized care. My experience was overwhelmingly positive, and the results speak for themselves. If you are considering a smile makeover, I highly recommend Elite Dental Care for their exceptional service and expertise.",
      author: "PrabhuRam",
      rating: 5
    },
    {
      review: "Recently we had done RCT for our 6 years old Son. Dr. Shobana took great care in treating my son. She made my kid understand the complete process and made him completely comfortable before the treatment. Thank You! Definitely would recommend for any dental care.",
      author: "Kannan K",
      rating: 5
    },
    {
      review: "I recently visited Elite Dental care for my kid and I must say it was an outstanding experience from start to finish. The clinic is impeccably clean and modern, which immediately put me at ease. The waiting area was comfortable, and I didn't have to wait long before being called in for my appointment. Doctor Shobana has a lot of patience especially to treat for a kid of 4 years. I would highly recommend Elite Dental clinic for anyone in need of dental services.",
      author: "Sandeep Dagaddappula",
      rating: 5
    },
    {
      review: "I consulted for Root canal treatment in Elite Dental Care. I admire Dr. Shobana for her patience and the way she approaches to treat. One of the best doctor for dental care I ever consulted here before. I recommend Dr. Shobana for any kind of dental problems. I wish Dr. Shobana for long life and do service to the society.",
      author: "GMD San",
      rating: 5
    },
  
  {
      review: "I consulted for Root canal treatment in Elite Dental Care. I admire Dr. Shobana for her patience and the way she approaches treatment. One of the best doctors for dental care I ever consulted here before. I recommend Dr. Shobana for any kind of dental problems. I wish Dr. Shobana a long life and continued service to society.",
      author: "GMD San",
      rating: 5
    },
    {
      review: "Very good treatment. Excellent hospitality and affordable charges. I strongly recommend this doctor for any kind of dental consultation. Talented and patient-friendly.",
      author: "Srikanth K",
      rating: 5
    },
    {
      review: "I went to Elite Dental Care to remove my wisdom tooth (already went 2 times for my family members) which had no space to come out and got infected too. Initially, I was afraid to remove it since it is a wisdom tooth and thought it would cause more pain and swelling. But Dr. Shobana handled it very carefully and removed the tooth within 15 minutes without any pain. I took my regular lunch after tooth removal and felt very comfortable from the next day for my day-to-day activities. Dr. Shobana called me, checked on me, and suggested things for fast healing, which was really good. The clinic atmosphere is also very good. This is a highly recommended dental clinic if you are in the OMR surroundings. The cost for treatments is also very reasonable.",
      author: "Suresh Kumar",
      rating: 5
    },
    {
      review: "I got RCT for my wife done here, and it was a great experience. I would really like to thank Dr. Shobana for being a wonderful doctor throughout the procedure. This place is highly recommended for any tooth-related concerns!",
      author: "Vivek Navik",
      rating: 5
    },
    {
      review: "Wonderful dental clinic. My mother was treated very gently for a complete tooth set change.",
      author: "Diguna Vignesa Kamesh Natarajan",
      rating: 5
    },
    {
      review: "My mother had done a root canal treatment at Elite Dental Clinic. Dr. Shobana did a great job patiently. Not only that, throughout the treatment, she often inquired about my mother's health, whether she had pain or not. I am fully satisfied with the treatment from Dr. Shobana. This is the best dental clinic in Kelambakkam.",
      author: "Viji Sathish",
      rating: 5
    },
    {
      review: "To my favorite dentist, Dr. Shobana, thank you for giving me my smile back! She has a very caring, compassionate, and friendly approach with a desire to make her patients happy with their results. If you need quality at an affordable price, this Elite Dental Clinic is highly recommended. Come check out this place.",
      author: "Bharath Ram",
      rating: 5
    },
    {
      review: "Great experience. The ambiance was good. Friendly approach by Dr. Shobana. I will refer to my family and friends.",
      author: "Karthick JKT",
      rating: 5
    },
    {
      review: "Excellent care and skilled doctor. Had dental check, tooth cleaning, and a cavity filling done.",
      author: "Padmavathi Gopalan",
      rating: 5
    },
    {
      review: "I had a wisdom tooth removal with less pain. The treatment was done very well by Dr. Shobana. She clearly showed it on a digital screen and explained the problem thoroughly. She approaches us with love and affection. Peaceful atmosphere and easy to reach. Surely, I will recommend this place to everyone. Affordable prices. Thank you, Dr. Shobana, for your satisfactory treatment.",
      author: "Sruthi Deevena",
      rating: 5
    },
    {
      review: "At last, a genuine dental clinic in Kelambakkam. I had cavity issues and I was recently there to get my teeth checked. Neat and clean environment. Friendly approach towards patients. Nominal fees. Thanks to Dr. Shobana.",
      author: "Monisha Natarajan",
      rating: 5
    },
    {
      review: "A very good clinic and friendly doctor, Dr. Shobana. Went here for tooth extraction and RCT procedure. The treatment is painless and effective.",
      author: "Praveen Kumar",
      rating: 5
    },
    {
      review: "I approached this clinic for toothache and Dr. Shobana patiently explained the problems and appropriate treatment. I got my wisdom tooth extraction and filling done here. The procedure was very smooth. The clinic is super clean and the way the doctors approach and make you comfortable during the surgical procedure was excellent. Overall, it's a pleasant experience. I will highly recommend this place for residents nearby (Padur and Thaiyur).",
      author: "Jaihind",
      rating: 5
    },
    {
      review: "It was a really nice experience. The clinic was neat and properly maintained.",
      author: "Akalya Murugesan",
      rating: 5
    },
    {
      review: "A complete solution to all the dental problems. Hospitality is absolutely amazing. Recommending Elite Dental Care.",
      author: "Lekshmi Anil",
      rating: 5
    },
    {
      review: "I had a clear and clean treatment. I am so happy about this. Now I am able to eat all the crunchy snacks. Thanks a lot to Elite Dental Care.",
      author: "Subishesh",
      rating: 5
    },
   
  ];