// // import { Container, Row, Col } from 'react-bootstrap';
// // import './Footer.css';
// // import React from 'react';
// // import { Navbar, Nav } from 'react-bootstrap';
// // import { Link } from 'react-router-dom';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faPhone, faEnvelope, faHome, faTooth, faInfoCircle, faImages } from '@fortawesome/free-solid-svg-icons';
// // import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

// // const Footer = () => {
// //   return (
// //     <footer className="footer bg-dark text-white text-center text-lg-start">
// //       <Container className="p-4">
// //         <Row>
// //           <Col lg={4} md={6} sm={12} className="mb-4 mb-md-0">
// //             <div className="footer-box shadow-sm p-3 bg-white rounded">
// //               <h5 className="text-uppercase">About Me</h5>
// //               <p>
// //               Dr. Shobana Thevi  is a dentist praticising in Elite Dental Care, Kelambakkam. She has completed her UG degree in 2016 with distinction. She has a total of 8 years of experience in dentistry. She studied in Adhiparasakthi Dental College and Hospital, Melmaruvathur. She published a journal on Oral hygiene awareness of children among medical students.
// //               </p>
// //             </div>
// //           </Col>
// //           <Col lg={4} md={6} sm={12} className="mb-4 mb-md-0">
// //             <div className="footer-box shadow-sm p-3 bg-white rounded">
// //               <h5 className="text-uppercase">Working Hours</h5>
// //               <p>Mon - Sat</p>
// //               <p>10:00 AM - 01:00 PM</p>
// //               <p>04:00 PM - 08:00 PM</p>
// //             </div>
// //           </Col>
// //           <Col lg={4} md={6} sm={12} className="mb-4 mb-md-0">
// //             <div className="footer-box shadow-sm p-3 bg-white rounded">
// //               <h5 className="text-uppercase">Contacts</h5>
// //               <p>Phone: +91-8122132314</p>
// //               <p>Email:<a href="https://mail.google.com/mail/?view=cm&fs=1&to=elitedentalcare2023@gmail.com" target="_blank" rel="noopener noreferrer" className="footer-link">elitedentalcare2023@gmail.com</a></p>
// //               <p>No.23, Veeranam Road, Kelambakkam-603103</p>
// //               <a href="https://www.google.co.in/maps/dir/12.7855993,80.2163737/Elite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist,+No:23,+Veeranam+Rd,+Kelambakkam,+Tamil+Nadu+603103/@12.7862194,80.2144962,17z/data=!3m1!4b1!4m18!1m7!3m6!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2sElite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist!8m2!3d12.7872421!4d80.2178487!16s%2Fg%2F11kb9g3vpv!4m9!1m1!4e1!1m5!1m1!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2m2!1d80.2178487!2d12.7872421!3e3?entry=ttu" target="_blank" rel="noopener noreferrer" className="footer-link text-primary">
// //                 Directions &rarr;
// //               </a>
// //               <div className="social-icons">
// //                 <Nav.Link href="https://www.instagram.com/elitedentalcare23/" target="_blank" className="social-link">
// //                   <FontAwesomeIcon icon={faInstagram} style={{ color: '#E1306C', fontSize: '30px' }} />
// //                 </Nav.Link>
// //                 <Nav.Link href="https://www.facebook.com/profile.php?id=61560282252690" target="_blank" className="social-link">
// //                   <FontAwesomeIcon icon={faFacebook} style={{ color: '#3b5998', fontSize: '30px' }} />
// //                 </Nav.Link>
// //               </div>
// //             </div>
// //           </Col>
// //         </Row>
// //       </Container>
// //       <div className="footer-bottom text-center p-3 bg-secondary">
// //         &copy; {new Date().getFullYear()} Elite Dental Care Kelambakkam
// //       </div>
// //     </footer>
// //   );
// // }

// // export default Footer;


// import { Container, Row, Col } from 'react-bootstrap';
// import './Footer.css';
// import React from 'react';
// import { Navbar, Nav } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone, faEnvelope, faHome, faTooth, faInfoCircle, faImages } from '@fortawesome/free-solid-svg-icons';
// import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

// const Footer = () => {
//   return (
//     <footer className="footer bg-dark text-white text-center text-lg-start">
//       <Container className="p-4">
//         <Row>
//           <Col lg={4} md={6} sm={12} className="mb-4 mb-md-0">
//             <div className="footer-box shadow-sm p-3 bg-white rounded">
//               <h5 className="text-uppercase">About Me</h5>
//               <p>
//                 Dr. Shobana Thevi is a dentist practicing in Elite Dental Care, Kelambakkam. She has completed her UG degree in 2016 with distinction. She has a total of 8 years of experience in dentistry. She studied in Adhiparasakthi Dental College and Hospital, Melmaruvathur. She published a journal on Oral hygiene awareness of children among medical students.
//               </p>
//             </div>
//           </Col>
//           <Col lg={4} md={6} sm={12} className="mb-4 mb-md-0">
//             <div className="footer-box shadow-sm p-3 bg-white rounded">
//               <h5 className="text-uppercase">Working Hours</h5>
//               <p>Mon - Sat</p>
//               <p>10:00 AM - 01:00 PM</p>
//               <p>04:00 PM - 08:00 PM</p>
//             </div>
//           </Col>
//           <Col lg={4} md={6} sm={12} className="mb-4 mb-md-0">
//             <div className="footer-box shadow-sm p-3 bg-white rounded">
//               <h5 className="text-uppercase">Contact Us</h5>
//               <p>Phone: +91-8122132314</p>
//               <p>Email:<a href="mailto:elitedentalcare2023@gmail.com" target="_blank" rel="noopener noreferrer" className="footer-link"> elitedentalcare2023@gmail.com</a></p>
//               <p>No.23, Veeranam Road, Kelambakkam-603103</p>
//               <a href="https://www.google.co.in/maps/dir/12.7855993,80.2163737/Elite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist,+No:23,+Veeranam+Rd,+Kelambakkam,+Tamil+Nadu+603103/@12.7862194,80.2144962,17z/data=!3m1!4b1!4m18!1m7!3m6!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2sElite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist!8m2!3d12.7872421!4d80.2178487!16s%2Fg%2F11kb9g3vpv!4m9!1m1!4e1!1m5!1m1!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2m2!1d80.2178487!2d12.7872421!3e3?entry=ttu" target="_blank" rel="noopener noreferrer" className="footer-link text-primary">
//                 Directions &rarr;
//               </a>
//               <div className="social-icons">
//                 <Nav.Link href="https://www.instagram.com/elitedentalcare23/" target="_blank" className="social-link">
//                   <FontAwesomeIcon icon={faInstagram} style={{ color: '#E1306C', fontSize: '30px' }} />
//                 </Nav.Link>
//                 <Nav.Link href="https://www.facebook.com/profile.php?id=61560282252690" target="_blank" className="social-link">
//                   <FontAwesomeIcon icon={faFacebook} style={{ color: '#3b5998', fontSize: '30px' }} />
//                 </Nav.Link>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className="footer-bottom text-center p-3 bg-secondary">
//         &copy; {new Date().getFullYear()} Elite Dental Care Kelambakkam
//       </div>
//     </footer>
//   );
// }

// export default Footer;


// import { Container, Row, Col } from 'react-bootstrap';
// import './Footer.css';
// import React from 'react';
// import { Nav } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

// const Footer = () => {
//   return (
//     <footer className="footer bg-dark text-white text-center text-lg-start">
//       <Container>
//         <Row>
//           <Col lg={3} md={6} sm={12} className="mb-4">
//             <div className="footer-box">
//               <h5 className="text-uppercase">About Me</h5>
//               <p>
//                 Dr. Shobana Thevi is a dentist practicing in Elite Dental Care, Kelambakkam. She has completed her UG degree in 2016 with distinction. She has a total of 8 years of experience in dentistry. She studied in Adhiparasakthi Dental College and Hospital, Melmaruvathur. She published a journal on Oral hygiene awareness of children among medical students.
//               </p>
//             </div>
//           </Col>
//           <Col lg={3} md={6} sm={12} className="mb-4">
//             <div className="footer-box">
//               <h5 className="text-uppercase text-center">Working Hours</h5>
//               <p className="text-center">Mon - Sat</p>
//               <p className="text-center">10:00 AM - 01:00 PM</p>
//               <p className="text-center">04:00 PM - 08:00 PM</p>
//             </div>
//           </Col>
//           <Col lg={3} md={6} sm={12} className="mb-4">
//             <div className="footer-box">
//               <h5 className="text-uppercase text-center">Contact Us</h5>
//               <p className="text-center">Phone: +91-8122132314</p>
//               <p className="text-center">Email:<a href="mailto:elitedentalcare2023@gmail.com" target="_blank" rel="noopener noreferrer" className="footer-link"> elitedentalcare2023@gmail.com</a></p>
//               <p className="text-center">No.23, Veeranam Road, Kelambakkam-603103</p>
//               <a href="https://www.google.co.in/maps/dir/12.7855993,80.2163737/Elite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist,+No:23,+Veeranam+Rd,+Kelambakkam,+Tamil+Nadu+603103/@12.7862194,80.2144962,17z/data=!3m1!4b1!4m18!1m7!3m6!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2sElite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist!8m2!3d12.7872421!4d80.2178487!16s%2Fg%2F11kb9g3vpv!4m9!1m1!4e1!1m5!1m1!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2m2!1d80.2178487!2d12.7872421!3e3?entry=ttu" target="_blank" rel="noopener noreferrer" className="footer-link text-primary text-center">
//                 Directions &rarr;
//               </a>
//               <div className="social-icons text-center">
//                 <Nav.Link href="https://www.instagram.com/elitedentalcare23/" target="_blank" className="social-link d-inline-block">
//                   <FontAwesomeIcon icon={faInstagram}  style={{ color: '#E1306C' }}  />
//                 </Nav.Link>
//                 <Nav.Link href="https://www.facebook.com/profile.php?id=61560282252690" target="_blank" className="social-link d-inline-block">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </Nav.Link>
//               </div>
//             </div>
//           </Col>
//           <Col lg={3} md={6} sm={12} className="mb-4">
//             <div className="footer-box">
//               <h5 className="text-uppercase">Our Services</h5>
//               <ul className="list-unstyled">
//                 <li>Teeth Whitening</li>
//                 <li>Bonding</li>
//                 <li>Checkups</li>
//                 <li>Cosmetic Procedures</li>
//                 <li>Dental Implants</li>
//                 <li>Dentures and Bridges</li>
//                 <li>Emergency Care</li>
//                 <li>Extractions</li>
//                 <li>Fillings and Sealants</li>
//                 <li>Laser Dentistry</li>
//                 <li>Mouth Guards</li>
//                 <li>Online Dentist Booking</li>
//                 <li>Oral Surgery</li>
//                 <li>Root Canals</li>
//                 <li>Teeth Reshaping</li>
//                 <li>Veneers and Crowns</li>
//                 <li>X-ray</li>
//               </ul>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className="footer-bottom text-center p-3 bg-secondary">
//         &copy; {new Date().getFullYear()} Elite Dental Care Kelambakkam
//       </div>
//     </footer>
//   );
// }

// export default Footer;

// import { Container, Row, Col } from 'react-bootstrap';
// import './Footer.css';
// import React from 'react';
// import { Nav } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

// const Footer = () => {
//   return (
//     <footer className="footer bg-dark text-white text-center">
//       <Container>
//         <Row className="justify-content-center">
//           <Col lg={4.5} md={6} sm={12} className="mb-4">
//             <div className="footer-box">
//               <h5 className="text-uppercase" style={{color:'black',fontSize:'bold'}}>Working Hours</h5>
//               <p><span style={{color:'black',fontWeight:"bold"}}>Monday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
//               <p><span style={{color:'black',fontWeight:"bold"}}>Tuesday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
//               <p><span style={{color:'black',fontWeight:"bold"}}>Wednesday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
//               <p><span style={{color:'black',fontWeight:"bold"}}>Thursday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
//               <p><span style={{color:'black',fontWeight:"bold"}}>Friday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
//               <p><span style={{color:'black',fontWeight:"bold"}}>Saturday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
//               <p><span style={{color:'black',fontWeight:"bold"}}>Sunday:</span> Closed (Holiday)</p>
//             </div>
//           </Col>
//           <Col lg={3} md={6} sm={12} className="mb-4">
//             <div className="footer-box">
//               <h5 className="text-uppercase" style={{color:"black", fontSize:'bold'}}>Contact Us</h5>
//               <p>Phone: +91-8122132314</p>
//               <p>Email:<a href="mailto:elitedentalcare2023@gmail.com" target="_blank" rel="noopener noreferrer" className="footer-link"> elitedentalcare2023@gmail.com</a></p>
//               <p>No.23, Veeranam Road, Kelambakkam-603103</p>
//               <a href="https://www.google.co.in/maps/dir/12.7855993,80.2163737/Elite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist,+No:23,+Veeranam+Rd,+Kelambakkam,+Tamil+Nadu+603103/@12.7862194,80.2144962,17z/data=!3m1!4b1!4m18!1m7!3m6!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2sElite+Dental+Care+-+A+Premier+Multispeciality+Dental+Clinic+and+Best+Dentist!8m2!3d12.7872421!4d80.2178487!16s%2Fg%2F11kb9g3vpv!4m9!1m1!4e1!1m5!1m1!1s0x3a5251e3047c93b1:0x17f89e7ede411525!2m2!1d80.2178487!2d12.7872421!3e3?entry=ttu" target="_blank" rel="noopener noreferrer" className="footer-link text-primary">
//                 Directions &rarr;
//               </a>
//               <div className="social-icons">
//                 <Nav.Link href="https://www.instagram.com/elitedentalcare23/" target="_blank" className="social-link d-inline-block instagram">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </Nav.Link>
//                 <Nav.Link href="https://www.facebook.com/profile.php?id=61560282252690" target="_blank" className="social-link d-inline-block facebook">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </Nav.Link>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className="footer-bottom text-center p-3 bg-secondary">
//         &copy; {new Date().getFullYear()} Elite Dental Care Kelambakkam
//       </div>
//     </footer>
//   );
// }

// export default Footer;


import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white text-center">
      <Container>
        <Row className="justify-content-center">
          <Col lg={4.5} md={6} sm={12} className="mb-4">
            <div className="footer-box">
              <h5 className="text-uppercase" style={{color:'black',fontSize:'bold'}}>Working Hours</h5>
              <p><span style={{color:'black',fontWeight:"bold"}}>Monday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
              <p><span style={{color:'black',fontWeight:"bold"}}>Tuesday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
              <p><span style={{color:'black',fontWeight:"bold"}}>Wednesday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
              <p><span style={{color:'black',fontWeight:"bold"}}>Thursday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
              <p><span style={{color:'black',fontWeight:"bold"}}>Friday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
              <p><span style={{color:'black',fontWeight:"bold"}}>Saturday:</span> 10:00 AM - 01:00 PM, 04:00 PM - 08:00 PM</p>
              <p><span style={{color:'black',fontWeight:"bold"}}>Sunday:</span> Closed (Holiday)</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} className="mb-4">
            <div className="footer-box">
              <h5 className="text-uppercase" style={{color:"black", fontSize:'bold'}}>Contact Us</h5>
              <p>Phone: +91-8122132314</p>
              <p>Email:<a href="mailto:elitedentalcare2023@gmail.com" target="_blank" rel="noopener noreferrer" className="footer-link"> elitedentalcare2023@gmail.com</a></p>
              <p>No.23, Veeranam Road, Kelambakkam-603103</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=Elite+Dental+Care,+No:23,+Veeranam+Rd,+Kelambakkam,+Tamil+Nadu+603103" target="_blank" rel="noopener noreferrer" className="footer-link text-primary">
                Directions &rarr;
              </a>
              <div className="social-icons">
                <Nav.Link href="https://www.instagram.com/elitedentalcare23/" target="_blank" className="social-link d-inline-block instagram">
                  <FontAwesomeIcon icon={faInstagram} />
                </Nav.Link>
                <Nav.Link href="https://www.facebook.com/profile.php?id=61560282252690" target="_blank" className="social-link d-inline-block facebook">
                  <FontAwesomeIcon icon={faFacebook} />
                </Nav.Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom text-center p-3 bg-secondary">
        &copy; {new Date().getFullYear()} Elite Dental Care Kelambakkam
      </div>
    </footer>
  );
}

export default Footer;
